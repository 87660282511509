<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="specificationsDataOverviewCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="specs" @click="changeExportParams('item-specs')">{{ $t('message.specs') }}</v-tab>
                        <v-tab key="products" @click="changeExportParams('product-groups')">{{ $t('message.product') }}</v-tab>
                        <v-tab key="designs" @click="changeExportParams('design-profile')">{{ $t('message.designProfile') + '*' }}</v-tab>
                        <v-tab key="species" @click="changeExportParams('species')">{{ $t('message.species') }}</v-tab>
                        <v-tab key="densities" @click="changeExportParams('densities')">{{ $t('message.density') + '*' }}</v-tab>
                        <v-tab key="compositions" @click="changeExportParams('compositions')">{{ $t('message.composition') + '*' }}</v-tab>
                        <v-tab key="glues" @click="changeExportParams('glues')">{{ $t('message.glue') + '*' }}</v-tab>
                        <v-tab key="surfaces" @click="changeExportParams('surfaces')">{{ $t('message.surfaceEdges') + '*' }}</v-tab>
                        <v-tab key="finishings" @click="changeExportParams('finishings')">{{ $t('message.finishing') + '*' }}</v-tab>
                        <v-tab key="grades" @click="changeExportParams('grades')">{{ $t('message.grades') }}</v-tab>
                        <v-tab key="mcs" @click="changeExportParams('mcs')">{{ $t('message.mc') }}</v-tab>
                        <v-tab key="thickness" @click="changeExportParams('thickness')">{{ $t('message.thickness') }}</v-tab>
<!--                        <v-tab key="widths" @click="changeExportParams('widths')">{{ $t('message.width') + '*' }}</v-tab>-->
<!--                        <v-tab key="lengths" @click="changeExportParams('lengths')">{{ $t('message.length') + '*' }}</v-tab>-->
                        <v-tab key="certifications" @click="changeExportParams('certifications')">{{ $t('message.certification') }}</v-tab>
                    </v-tabs>
                    <span class="ml-3 deep-orange--text font-italic font-sm">{{ '* - ' + 'used only for Supplier Products' }}</span>
                    <v-tabs-items v-model="specificationsDataOverviewCurrentTab">
                        <v-tab-item key="specs-list">
                            <v-card flat>
                                <ItemSpecsListing
                                    :add-dialog="openAddItemSpecDialog"
                                    :key="itemSpecsListKey"
                                    @add-dialog-closed="addItemSpecDialogClosed"
                                    @reload="reloadItemSpecsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="products-list">
                            <v-card flat>
                                <ProductGroupsListing
                                    :add-dialog="openAddProductGroupDialog"
                                    :key="productGroupsListKey"
                                    :id-to-edit="productToEdit"
                                    @add-dialog-closed="addProductGroupDialogClosed"
                                    @reload="reloadProductGroupsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="designs-list">
                            <v-card flat>
                                <DesignProfilesListing
                                    :key="designProfilesListKey"
                                    @reload="reloadDesignProfilesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="species-list">
                            <v-card flat>
                                <SpeciesListing
                                    :add-dialog="openAddSpeciesDialog"
                                    :key="speciesListKey"
                                    :id-to-edit="speciesToEdit"
                                    @add-dialog-closed="addSpeciesDialogClosed"
                                    @reload="reloadSpeciesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="densities-list">
                            <v-card flat>
                                <DensitiesListing
                                    :key="densitiesListKey"
                                    @reload="reloadDensitiesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="compositions-list">
                            <v-card flat>
                                <CompositionsListing
                                    :key="compositionsListKey"
                                    @reload="reloadCompositionsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="glues-list">
                            <v-card flat>
                                <GluesListing
                                    :key="gluesListKey"
                                    @reload="reloadGluesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="surfaces-list">
                            <v-card flat>
                                <SurfacesListing
                                    :key="surfacesListKey"
                                    @reload="reloadSurfacesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="finishings-list">
                            <v-card flat>
                                <FinishingsListing
                                    :key="finishingsListKey"
                                    @reload="reloadFinishingsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="grades-list">
                            <v-card flat>
                                <GradesListing
                                    :add-dialog="openAddGradeDialog"
                                    :key="gradesListKey"
                                    @add-dialog-closed="addGradeDialogClosed"
                                    @reload="reloadGradesList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="moisture-contents-list">
                            <v-card flat>
                                <MoistureContentsListing
                                    :add-dialog="openAddMoistureContentDialog"
                                    :key="moistureContentsListKey"
                                    @add-dialog-closed="addMoistureContentDialogClosed"
                                    @reload="reloadMoistureContentsList"
                                />
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="thickness-list">
                            <v-card flat>
                                <ThicknessListing
                                    :add-dialog="openAddThicknessDialog"
                                    :key="thicknessListKey"
                                    @add-dialog-closed="addThicknessDialogClosed"
                                    @reload="reloadThicknessList"
                                />
                            </v-card>
                        </v-tab-item>
<!--                        <v-tab-item key="widths-list">-->
<!--                            <v-card flat>-->
<!--                                <WidthsListing-->
<!--                                    :key="widthsListKey"-->
<!--                                    @reload="reloadWidthsList"-->
<!--                                />-->
<!--                            </v-card>-->
<!--                        </v-tab-item>-->
<!--                        <v-tab-item key="lengths-list">-->
<!--                            <v-card flat>-->
<!--                                <LengthsListing-->
<!--                                    :key="lengthsListKey"-->
<!--                                    @reload="reloadLengthsList"-->
<!--                                />-->
<!--                            </v-card>-->
<!--                        </v-tab-item>-->
                        <v-tab-item key="certifications-list">
                            <v-card flat>
                                <CertificationsListing
                                    :add-dialog="openAddCertificationDialog"
                                    :key="certificationsListKey"
                                    @add-dialog-closed="addCertificationDialogClosed"
                                    @reload="reloadCertificationsList"
                                />
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {isset} from "Helpers/helpers";
import LengthsListing from "Components/Appic/ProductSpecifications/LengthListing.vue";

const CertificationsListing = () => import('Components/Appic/CertificationsListing');
const CompositionsListing = () => import('Components/Appic/ProductSpecifications/CompositionsListing');
const DensitiesListing = () => import('Components/Appic/ProductSpecifications/DensitiesListing');
const DesignProfilesListing = () => import('Components/Appic/ProductSpecifications/DesignProfilesListing');
const ExportTable = () => import("Components/Appic/ExportTable");
const FinishingsListing = () => import('Components/Appic/ProductSpecifications/FinishingsListing');
const GluesListing = () => import('Components/Appic/ProductSpecifications/GluesListing');
const GradesListing = () => import("Components/Appic/GradesListing");
const ItemSpecsListing = () => import('Components/Appic/ItemSpecsListing');
const LengthListing = () => import('Components/Appic/ProductSpecifications/LengthListing');
const MoistureContentsListing = () => import("Components/Appic/MoistureContentsListing");
const ProductGroupsListing = () => import("Components/Appic/ProductGroupsListing");
const SpeciesListing = () => import("Components/Appic/SpeciesListing");
const SurfacesListing = () => import('Components/Appic/ProductSpecifications/SurfacesListing');
const ThicknessListing = () => import('Components/Appic/ThicknessListing');
const WidthsListing = () => import('Components/Appic/ProductSpecifications/WidthsListing');

export default {
    name: "SpecificationsDataOverview",
    components: {
        LengthsListing,
        CertificationsListing,
        CompositionsListing,
        DensitiesListing,
        DesignProfilesListing,
        ExportTable,
        FinishingsListing,
        GluesListing,
        GradesListing,
        ItemSpecsListing,
        MoistureContentsListing,
        ProductGroupsListing,
        SpeciesListing,
        SurfacesListing,
        ThicknessListing,
        WidthsListing
    },
    title: '',
    data() {
        return {
            loader: false,
            active: null,
            certificationsListKey: 0,
            colsToDelete: {
                start_col: 10,
                ncols: 1
            },
            compositionsListKey: 0,
            densitiesListKey: 0,
            designProfilesListKey: 0,
            fileName: 'sizes',
            finishingsListKey: 0,
            gluesListKey: 0,
            gradesListKey: 0,
            hasAddFunction: true,
            document: 'product-groups',
            itemSpecsListKey: 0,
            lengthsListKey: 0,
            moistureContentsListKey: 0,
            openAddCertificationDialog: false,
            openAddGradeDialog: false,
            openAddMoistureContentDialog: false,
            openAddProductGroupDialog: false,
            openAddItemSpecDialog: false,
            openAddSpeciesDialog: false,
            openAddThicknessDialog: false,
            productGroupsListKey: 0,
            productToEdit: null,
            speciesListKey: 0,
            surfacesListKey: 0,
            speciesToEdit: null,
            tableId: 'productGroupsTable',
            thicknessListKey: 0,
            widthsListKey: 0
        }
    },
    computed: {
        ...mapFields('runtime',{
            specificationsDataOverviewCurrentTab: 'specificationsDataOverviewCurrentTab'
        })
    },
    methods: {
        addCertificationDialogClosed () {
            this.openAddCertificationDialog = false
        },
        addGradeDialogClosed () {
            this.openAddGradeDialog = false
        },
        addMoistureContentDialogClosed () {
            this.openAddMoistureContentDialog = false
        },
        addProductGroupDialogClosed () {
            this.openAddProductGroupDialog = false
        },
        addItemSpecDialogClosed () {
            this.openAddItemSpecDialog = false
        },
        addSpeciesDialogClosed () {
            this.openAddSpeciesDialog = false
        },
        addThicknessDialogClosed () {
            this.openAddThicknessDialog = false
        },
        changeExportParams(itemType){
            switch (itemType) {
                case 'certifications':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'certifications'
                    this.hasAddFunction = true
                    this.document = 'certifications'
                    this.tableId = 'certificationsTable'
                    break
                case 'grades':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'grades'
                    this.hasAddFunction = true
                    this.document = 'grades'
                    this.tableId = 'gradesTable'
                    break
                case 'item-specs':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'itemSpecs'
                    this.hasAddFunction = true
                    this.document = 'item-specs'
                    this.tableId = 'itemSpecsTable'
                    break
                case 'mcs':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'moistureContents'
                    this.hasAddFunction = true
                    this.document = 'moisture-content'
                    this.tableId = 'moistureContentTable'
                    break
                case 'product-groups':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'productGroups'
                    this.hasAddFunction = true
                    this.document = 'product-groups'
                    this.tableId = 'productGroupsTable'
                    break
                case 'species':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'species'
                    this.hasAddFunction = true
                    this.document = 'species'
                    this.tableId = 'speciesTable'
                    break
                case 'thickness':
                    this.colsToDelete = {start_col: 0, ncols: 1}
                    this.fileName = 'thickness'
                    this.hasAddFunction = true
                    this.document = 'thickness'
                    this.tableId = 'thicknessTable'
                    break
            }
        },
        newItem (itemType) {
            switch(itemType){
                case 'certifications':
                    this.openAddCertificationDialog = true
                    break
                case 'grades':
                    this.openAddGradeDialog = true
                    break
                case 'item-specs':
                    this.openAddItemSpecDialog = true
                    break
                case 'moisture-content':
                    this.openAddMoistureContentDialog = true
                    break
                case 'product-groups':
                    this.openAddProductGroupDialog = true
                    break
                case 'species':
                    this.openAddSpeciesDialog = true
                    break
                case 'thickness':
                    this.openAddThicknessDialog = true
                    break
            }
        },
        reloadCertificationsList () {
            this.certificationsListKey = Math.floor(Math.random() * 100)
        },
        reloadCompositionsList () {
            this.compositionsListKey = Math.floor(Math.random() * 100)
        },
        reloadDensitiesList () {
            this.densitiesListKey = Math.floor(Math.random() * 100)
        },
        reloadDesignProfilesList () {
            this.designProfilesListKey = Math.floor(Math.random() * 100)
        },
        reloadFinishingsList () {
            this.finishingsListKey = Math.floor(Math.random() * 100)
        },
        reloadGluesList () {
            this.gluesListKey = Math.floor(Math.random() * 100)
        },
        reloadGradesList () {
            this.gradesListKey = Math.floor(Math.random() * 100)
        },
        reloadItemSpecsList () {
            this.itemSpecsListKey = Math.floor(Math.random() * 100)
        },
        reloadLengthsList () {
            this.lengthsListKey = Math.floor(Math.random() * 100)
        },
        reloadMoistureContentsList () {
            this.moistureContentsListKey = Math.floor(Math.random() * 100)
        },
        reloadProductGroupsList () {
            this.productGroupsListKey = Math.floor(Math.random() * 100)
        },
        reloadSpeciesList () {
            this.speciesListKey = Math.floor(Math.random() * 100)
        },
        reloadSurfacesList () {
            this.surfacesListKey = Math.floor(Math.random() * 100)
        },
        reloadThicknessList () {
            this.thicknessListKey = Math.floor(Math.random() * 100)
        },
        reloadWidthsList () {
            this.widthsListKey = Math.floor(Math.random() * 100)
        }
    },
    mounted() {
        if(isset(() => this.$route.query.panel)){
            switch(this.$route.query['panel']){
                case 'products':
                    this.specificationsDataOverviewCurrentTab = 0
                    if(isset(() => this.$route.query.id)) {
                        this.productToEdit = this.$route.query.id
                    }
                    break
                case 'species':
                    this.specificationsDataOverviewCurrentTab = 1
                    if(isset(() => this.$route.query.id)) {
                        this.speciesToEdit = this.$route.query.id
                    }
                    break
            }
        }
        switch(this.specificationsDataOverviewCurrentTab){
            case 0:
                this.changeExportParams('product-groups')
                break
            case 1:
                this.changeExportParams('species')
                break
            case 2:
                this.changeExportParams('certifications')
                break
            case 3:
                this.changeExportParams('grades')
                break
            case 4:
                this.changeExportParams('item-specs')
                break
            case 5:
                this.changeExportParams('mcs')
                break
            case 6:
                this.changeExportParams('thickness')
                break
        }
    }
}
</script>

<style scoped>
.v-tab {
    text-transform: none!important;
    padding: 0px 5px !important;
}
</style>